<template>
    <div>
        <v-img src="../assets/pho.jpg" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)" class="footpara p-2" aspect-ratio="4.5">
			<v-row id="contact">
				<v-col cols="12" md="4" sm="12"  align="center" class="mt-5 ">
					<a href="#" ><img  class="img-responsive Logofoter" src="../assets/logo.png" height="100" width="280" ></a>
				</v-col>
				<v-col cols="12" md="8" sm="12" class="mt-sm-15 infofooter">
					<h2><b style="color:white">HELP CENTER</b></h2>
					<b style="color:white">POUR PLUS D'INFORMATIONS SUR L'INSCRIPTION</b><br>
					<span style="color:white">Mr Khalil Sibari :</span> <a href="tel:+212602820075" class="contactAdn" style="text-decoration:none;">+212 602 82 00 75</a>
					<span style="color:white"> - E-mail :</span> <a href="mailto:khalil.sibari@ascrea.ma" class="contactAdn" style="text-decoration:none;">khalil.sibari@ascrea.ma</a><br>
				</v-col>
			</v-row>
		</v-img>
    </div>
</template>

<script>
export default {

  data: () => ({
  }),
  methods: {
    
},
mounted() {
},

};
</script>
<style >

</style>