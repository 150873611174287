<template>
    <v-container class="inscrire" grid-list-xs >
        <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#2E77A3"  >
                    Inscription
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-snackbar  v-model="snackbar" :color="snackColor"  timeout="5000" top>
            {{ msg }}
        </v-snackbar>
        <v-card class="pa-1">
            <v-card-title>
            <span class="headline" style="white-space: nowrap;">Formulaire d'inscription</span>
            </v-card-title>
            <v-list class="overflow-y-auto" >
            <v-card-text>
                <v-container v-if="!confirmed">
                <v-form  ref="form" v-model="valid" lazy-validation >
                    <v-row>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Nom*"
                            :rules="allRule"
                            v-model="nom"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Prenom*"
                            :rules="allRule"
                            v-model="prenom"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" >
                            <v-text-field label="Email*"
                            :rules="emailRules"
                            v-model="email"
                            class="my-input"
                            onpaste="return false"
                            autocomplete="nope"
                            @keydown.space.prevent
                            required>
                            </v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Téléphone"
                            type="tel"
                            v-model="tlf"
                            ></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-text-field label="Ville*"
                            :rules="allRule"
                            v-model="ville"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <!-- <v-text-field label="Spécialité*"
                            :rules="allRule"
                            v-model="specialite"
                            required></v-text-field> -->
                            <v-select
                            :items="items"
                            v-model="specialite"
                            label="Spécialité"
                            ></v-select>                    
                        </v-col>
                    </v-row>
                    </v-form>
                </v-container>
                <v-container v-else>
                    <v-alert class="text" text>
                        Les laboratoires dermatologiques Pierre Fabre vous remercie de votre inscription.<br>
                        Prière de vérifier votre Email pour plus d’informations sur l’inscription.
                    </v-alert>
                </v-container>
                <v-alert text prominent type="error" v-show="formNotValid" icon="mdi-cloud-alert">
                    Formulaire non complet
                </v-alert><br>
            </v-card-text>
            </v-list>
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="validate"
                    v-if="!confirmed"
                    :loading="inscribtn"
                >
                    S'inscrire
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
   data () {
      return {
        inscribtn:false,
        email:'',
        emailConferm:'',
        nom:'',
        prenom:'',
        ville:'',
        tlf:'',
        specialite:'',
        valid: true,
        dialog: false,
        confirmed: false,
        error:false,
        snackbar:false,
        snackColor:'',
        msg:'',
        formNotValid:false,
        
        emailRules: [
            v => !!v || 'E-mail est requis',
            v => /.+@.+/.test(v) || 'L\'email doit être valide',
        ],
        items: ['Médecin généraliste Privé','Médecin généraliste Public', 'Interne', 'Résident'],
        allRule: [v => !!v || 'Ce champ est requis',],
        domaineRule: [(v) => !!v || "Item is required"],
        select: { name: 'Morocco', code: 'MA' },
        fonctionsList: [
            'Professeur',
            'Spécialiste secteur public',
            'Spécialiste secteur privé',
            'Résident/interne',
            'Infirmier'
        ],
      }
    },
    mounted() {
     
    },
    methods: {
        validate () {
            if(this.$refs.form.validate()){
                this.inscribtn = true
                this.email = this.email.toLowerCase()
                this.axios({
                    method: 'post',
                    url: 'https://dexerylpfstream.hashtagsante.com/api/inscrit',
                    data: {
                        email:this.email ,
                        name:this.nom,
                        prenom:this.prenom,
                        tlf:this.tlf,
                        ville:this.ville,
                        specialite:this.specialite,
                    }
                }).then((res)=>{
                    if(res.data !='succes'){
                        this.snackMsg(res.data)
                        this.error=false
                        this.inscribtn=false
                    }
                    else{
                        this.clear()             
                        this.confirmed = true
                        this.error=false
                        this.inscribtn=false
                    }
                }, err =>{
                    if(err){
                    this.emailError('Cet email déjà inscrit')
                    console.log(err)
                    this.inscribtn=false
                    }
                });
            }
            else{
                this.formNotValid = true
            }
        },
        clear(){
            this.confirmed = false
            this.email = '',
            this.emailConferm='',
            this.nom = '' , 
            this.prenom = '' , 
            this.ville = '' , 
            this.specialite= ''
            this.tlf = '' ,
            this.formNotValid = false
        },
        detectFonction(){
            if(this.specialite === "Infirmier" || this.specialite === "Résident/interne"){
                this.session = ''
                return true
            }
            else{
                return false
            }
        },
        snackMsg(msg){
            msg == "succes" ? this.snackColor = "green" : this.snackColor = "red"
            msg == "succes" ? this.msg = " Cher Docteur,\nVous êtes bien inscrit, veuillez vérifier votre boite Email " : this.msg = msg 
            this.snackbar = true
        },
        emailError(msg){
            this.snackbar = true
            this.snackColor = "red"
            this.msg =  msg 
        }
    },
  }
</script>
<style>
h1{
 font-family: 'Montserrat';
}
.inscrireInfo{
    font-size:1.5em
}
@media (max-width: 480px) {
  
}
@media all and (max-width: 768px) {
  .btn{
    font-size:10px
    }
    .insbtn{
        font-size:1em
    }
    .textInsc{
        font-size:10px
    } 
}



.my-input input{
  text-transform: lowercase
}

.welcome_text{
  font-size: 1.5em;
}    
.btn{
    background-color: #FBB400 !important;
    border-radius: 10px;
}
.insbtn{
        font-size:30px

    }
.textInsc{
    font-size:20px
}  

.ps {
  height: 400px;
}

@media (max-width: 480px) {
    .insbtn{
        font-size:15px
    }
}
</style>