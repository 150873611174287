<template>
<v-img src="../../assets/pho.jpg" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)" class="comiteparrp mt-5">
        <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <v-col cols="12"  align="center">
                    <img  class="img-responsive " src="..\..\assets\brand.png" >
                </v-col>
            </v-row>
        </v-container>
</v-img>  
</template>
<script>
export default {
    data() {
        return {
            speakers: [
                 {name:"Aawatif Hayar",cv:"Présidente de l’Université Hassan II de Casablanca",
                  src:"awatif.png",},
                {name:"Hassan Radoine",cv:"Directeur Ecole d'Architecture, planification et design de l’ Université Mohammed VI Polytechnique.",
                  src:"hassan.png",},     
            ],
        }
    },
}
</script>
<style >
.compHead{
    font-size: 1.9375rem;
}
.comiteparrp{
    height: 330px!important;
}
.opaciteSeparat{
  background-color: rgba(0, 0, 0, 0.577);
  height: 400px!important;
}
@media all and (max-width: 768px) {

  .comiteparrp{
    height: 200px!important;
 }
}

</style>