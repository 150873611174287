<template>
<v-app>
  <app-header></app-header>
 <div  data-spy="scroll" data-target="#navbar-app" data-offset="0">
        <!-- <div id="propos">
            <accueil></accueil>
        </div> -->
        <!-- <div>
            <timer/>
        </div> -->
        <!-- <div id="theme">
            <thematique></thematique>
        </div> -->
        <!-- <div >
			<propos></propos>
		</div> -->
         <div id="axe">
            <scientifique></scientifique>
        </div> 
        <div id="inscription">
            <inscription class="mt-5 mb-5" />
        </div>
        <!-- <div id="publication">
            <axe2  class="mt-5 mb-5" ></axe2>
        </div> -->
    <!-- <div id="sponsors">
            <sponsors class="mt-5"/>
        </div>  -->
  </div> 
  <app-footer></app-footer>
</v-app>
</template>

<script>
    import AppHeader from './appHeader.vue';
   
    import scientifique from './landing/scientifique.vue';
    import inscription from './landing/inscription.vue';
   
    /* import sponsors from './landing/sponsors.vue'; */
    import AppFooter from './appFooter.vue';


export default {
  components: { AppHeader, AppFooter, scientifique ,inscription },

  data: () => ({
    scrollPosition: null,
    slideshow:"",
    dialog:false,
    vid:"",
    audio:true
  }),
  methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
    goTo(path){
           this.$router.push(path)
        },
    click: function() {
        this.$refs.inscriComponent.setValue();
    },

    slidenum(num){
      this.slideshow=num
     
    },

    
},
mounted() {
    window.addEventListener('scroll', this.updateScroll);

  

   this.slideshow=0

   
},

};
</script>
<style >
</style>