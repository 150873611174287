import Vue from 'vue';
import VueRouter from 'vue-router';
import index from './components/index';
//import ExampleComponent from './components/ExampleComponent';
Vue.use(VueRouter);

export default new VueRouter({
        mode:'history',
        routes:[
            {
                path:'/',
                redirect: '/landingpage'
            },
            {
                path:'/landingpage',
                component:index,
                
            },
            {   path: '*',
                redirect: '/landingpage'
            }
            
        ]
});
